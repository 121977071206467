import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

import {
  CheckDeviceService,
  TypeDevice,
} from '../../../shared/globals/services/check-device.service';

export enum modeGPS {
  Watch = 'Watch',
  CurrentLocation = 'CurrentLocation',
}

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  private gpsCoordinates$ = new BehaviorSubject<any>({});
  private gps: any;
  private activate = false;
  private mode: modeGPS;

  constructor(
    private checkDeviceService: CheckDeviceService,
    private androidPermissions: AndroidPermissions,
    private geolocation: Geolocation,
    private locationAccuracy: LocationAccuracy
  ) {}

  getGpsCoordinates$() {
    return this.gpsCoordinates$.asObservable();
  }

  activateGPS(activate: boolean, mode: modeGPS = modeGPS.CurrentLocation) {
    // Actualiza estado de activación
    this.activate = activate;
    this.mode = mode;

    if (this.checkDeviceService.DeviceIs === TypeDevice.mobile) {
      // Check if application having GPS access permission
      this.androidPermissions
        .checkPermission(
          this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION
        )
        .then(
          (result) => {
            if (result.hasPermission) {
              // If having permission show 'Turn On GPS' dialogue
              this.askToTurnOnGPS();
            } else {
              // If not having permission ask for permission
              this.requestGPSPermission();
            }
          },
          (err) => {
            alert(err);
          }
        );
    }

    if (this.checkDeviceService.DeviceIs === TypeDevice.browser) {
      this.getLocationCoordinates();
    }
  }

  requestGPSPermission() {
    this.locationAccuracy.canRequest().then((canRequest: boolean) => {
      if (canRequest) {
      } else {
        // Show 'GPS Permission Request' dialogue
        this.androidPermissions
          .requestPermission(
            this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION
          )
          .then(
            () => {
              // call method to turn on GPS
              this.askToTurnOnGPS();
            },
            (error) => {
              // Show alert if user click on 'No Thanks'
              alert(
                'requestPermission Error requesting location permissions ' +
                  error
              );
            }
          );
      }
    });
  }

  askToTurnOnGPS() {
    this.locationAccuracy
      .request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY)
      .then(
        () => {
          // When GPS Turned ON call method to get Accurate location coordinates
          this.getLocationCoordinates();
        },
        (error) =>
          alert(
            'Error requesting location permissions ' + JSON.stringify(error)
          )
      );
  }

  // Methos to get device accurate coordinates using device GPS
  getLocationCoordinates() {
    let coordinatesGPS: Array<number> = [];

    if (this.activate) {
      if (this.mode === modeGPS.Watch) {
        this.gps = this.geolocation
          .watchPosition()
          .subscribe((data: Geoposition) => {
            coordinatesGPS = [data.coords.longitude, data.coords.latitude];
            this.gpsCoordinates$.next(coordinatesGPS);
          });
      } else {
        this.geolocation
          .getCurrentPosition()
          .then((data) => {
            coordinatesGPS = [data.coords.longitude, data.coords.latitude];
            this.gpsCoordinates$.next(coordinatesGPS);
          })
          .catch((error) => {
            alert('Error getting location' + error);
          });
      }
    } else {
      if (this.mode === modeGPS.Watch) {
        this.gps.unsubscribe();
      }
    }
  }
}
