import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

import { Login } from '../models/login';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private url = environment.apiUrl + 'Login/';

  constructor(private http: HttpClient, private router: Router) {}

  public get currentUserValue(): Login {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  // tslint:disable-next-line: max-line-length
  public login$ = (login: Login): Observable<Login> =>
    this.http.post<any>(this.url + 'Autenticar', login, httpOptions).pipe(
      map((data) => {
        const log = data.datos;

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(log));

        return log;
      }),
      catchError((error) => of(console.error(error)))
    );

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');

    // Va a Login
    this.router.navigate(['/Login']);
  }
}
