import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  constructor() {}

  public set(key: string, value: any): void {
    if (key) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public get(key: string): any {
    if (key) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  }

  public delete(key: string): void {
    localStorage.removeItem(key);
  }
}
