import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './shared/globals/services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    data: { animation: 'isRight' },
  },
  {
    path: 'Login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'Infraestructura',
    loadChildren: () =>
      import('./modules/infraestructura/infraestructura.module').then(
        (m) => m.InfraestructuraModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'Ciudadano',
    loadChildren: () =>
      import('./modules/reporte-ciudadano/reporte-ciudadano.module').then(
        (m) => m.ReporteCiudadanoModule
      ),
  },
  {
    path: 'ReporteSalud',
    loadChildren: () =>
      import('./modules/reporte-salud/reporte-salud.module').then(
        (m) => m.ReporteSaludModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
    data: { animation: 'isRight' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
