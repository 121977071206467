import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';

export enum TypeDevice {
  browser = 'browser',
  mobile = 'mobile'
}

@Injectable({
  providedIn: 'root'
})
export class CheckDeviceService {
  public DeviceIs =	'';

  constructor(
    private device: Device,
    private platform: Platform
  ) {
    this.platform.ready().then(async () => {
      await this._detect();
    });
  }

   private _detect() {
    if (this.device.platform === null || this.device.platform === '' || this.device.platform === 'browser') {
      this.DeviceIs = TypeDevice.browser;
    } else {
      this.DeviceIs = TypeDevice.mobile;
    }
  }
}
