import { Injectable } from '@angular/core';
import { DragBox } from 'ol/interaction';
import { MapService } from './map.service';
import { platformModifierKeyOnly } from 'ol/events/condition';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  private dragBox: DragBox;
  private active = false;

  constructor(private mapService: MapService) {}

  setActiveDragBox(active: boolean) {
    if (active) {
      this._addInteraction();

      this.active = true;
    } else {
      this._removeInteraction();

      this.active = false;
    }
  }

  getIsActiveDragBox() {
    if (typeof this.dragBox === 'undefined') {
      return false;
    } else {
      return this.active;
    }
  }

  getDragBox() {
    return this.dragBox;
  }

  private _addInteraction() {
    if (this.getIsActiveDragBox()) {
      return;
    }

    this.dragBox = new DragBox({ condition: platformModifierKeyOnly });
    this.mapService.addInteraction(this.dragBox);

    // console.log('StatsService -> _addInteraction -> dragBox', this.dragBox);
  }

  private _removeInteraction() {
    this.mapService.removeInteraction(this.dragBox);
    this.dragBox = undefined;

    // console.log('StatsService -> _removeInteraction -> dragBox', this.dragBox);
  }
}
