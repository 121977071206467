import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TermsComponent } from '../terms/terms.component';
import { MapService } from '../../../modules/map/services/map.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {

  public loadMap = false;

  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true
  };

  constructor(
    public modalController: ModalController,
    private mapService: MapService
  ) { }

  ngOnInit() {
    this.verifyLoadMap();
  }

  verifyLoadMap() {
    this.mapService.getMapLoaded$()
      .subscribe(loaded => {
        if (loaded) {
          this.loadMap = loaded;
        }
    });
  }

  clickAceptarTerminos() {
    this.modalController.dismiss();
  }

  async clickVerTerminos() {
    const modal = await this.modalController.create({
      component: TermsComponent,
      backdropDismiss: false
    });

    return await modal.present();
  }
}
