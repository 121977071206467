import { Injectable } from '@angular/core';

import { Heatmap as HeatmapLayer, Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Cluster } from 'ol/source';
import GeoJSON from 'ol/format/GeoJSON';
import { Circle, Fill, Stroke, Style, Text, Icon } from 'ol/style';

import { Layer } from '../models/layer';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { SymbolType } from 'ol/style/literal';
import { Point } from 'ol/geom';
import CircleStyle from 'ol/style/Circle';
import VectorImageLayer from 'ol/layer/VectorImage';
import { TypeVector } from 'src/app/shared/enums/TypeVectors';
import { TypeFormat } from 'src/app/shared/enums/TypeFormat';
import { Download } from 'src/app/shared/utils/download';

@Injectable({
  providedIn: 'root',
})
export class VectorService {
  // Layer
  private vector: any;
  private clusterSource: Cluster;
  private vectorSource: VectorSource;
  private fillColor = '-1, -1, -1';
  private fillTransparent = 0.6;
  private strokeColor = '0, 0, 0';
  private styleFeature: any;
  private iconDefault = '../../../../assets/icon/qmap/icon-ubicar.png';
  private typeGeometry;
  private layer: Layer;

  // data
  private data: any[] = [];

  // Config map service
  private urlService: string;
  private nameVector: string;

  constructor() {}

  create(layer: Layer, urlService: string) {
    this.layer = layer;

    // Captura el nombre del layer
    this.nameVector = layer.name;

    // Crea vector source
    this.vectorSource = new VectorSource();

    // Captura nombre del layer para la url servicio
    this.urlService = urlService;

    // Establece estilos
    this.fillColor = layer.rgb_fill_color_vector
      ? layer.rgb_fill_color_vector
      : '-1, -1, -1';
    this.fillTransparent = layer.rgb_fill_color_vector ? 0.6 : 0.1;
    this.strokeColor = layer.rgb_stroke_color_vector
      ? layer.rgb_stroke_color_vector
      : '0, 0, 0';
    this.styleFeature = layer.clasificaction_icon_vector
      ? layer.clasificaction_icon_vector
      : layer.icon_vector
      ? layer.icon_vector
      : this.iconDefault;
    this.typeGeometry = layer.type_geometry;

    // Crea vector de acuerdo al tipo
    switch (layer.type_vector) {
      case TypeVector.VECTOR:
        this.createVector();

        break;
      case TypeVector.VECTOR_WEBGL:
        this.createVectorWebGL();

        break;
      case TypeVector.CLUSTER:
        this.createCluster();

        break;
      case TypeVector.HEATMAP:
        this.createHeatMap();

        break;
    }
  }

  private createVector() {
    // Crea y configura vector
    this.vector = new VectorLayer({
      source: this.vectorSource,
      style: this.styleVector.bind(this),
    });
  }

  private createVectorWebGL() {
    if (this.layer.type_geometry === 'point') {
      const vectorSourcePoint = this.vectorSource as VectorSource<Point>;

      this.vector = new WebGLPointsLayer({
        source: vectorSourcePoint,
        style: this.styleVectorWebGL(),
        disableHitDetection: false,
      });
    } else {
      this.vector = new VectorImageLayer({
        imageRatio: 2,
        source: this.vectorSource,
        style: this.styleVector.bind(this),
      });
    }
  }

  private createCluster() {
    // crea vector cluster
    this.clusterSource = new Cluster({
      distance: 50,
      source: this.vectorSource,
    });

    // Crea y configura vector
    this.vector = new VectorLayer({
      source: this.clusterSource,
      style: this.styleCluster.bind(this),
    });
  }

  private createHeatMap() {
    const vectorSourcePoint = this.vectorSource as VectorSource<Point>;

    this.vector = new HeatmapLayer({
      source: vectorSourcePoint,
      blur: 10,
      radius: 10,
    });

    this.vector.getSource().on('addfeature', (event: any) => {
      event.feature.set('weight', 1);
    });
  }

  async changeTypeVector(type_vector: TypeVector): Promise<void> {
    // Libera recursos
    this.releaseResources();

    // Crea vector source
    this.vectorSource = new VectorSource();

    // Crear vector de acuerdo al tipo seleccionado por el usuario
    switch (type_vector) {
      case TypeVector.VECTOR:
        this.createVector();

        break;
      case TypeVector.VECTOR_WEBGL:
        this.createVectorWebGL();

        break;
      case TypeVector.CLUSTER:
        this.createCluster();

        break;
      case TypeVector.HEATMAP:
        this.createHeatMap();

        break;
    }
  }

  releaseResources() {
    // Libera recursos
    this.clusterSource = null;
    this.vector = null;
    this.vectorSource = null;
  }

  async load(): Promise<void> {
    // tslint:disable-next-line: max-line-length
    const url = this.urlService + this.getParam(TypeFormat.GEOJSON) + this.nameVector;

    return fetch(url)
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        if (data) {
          await this.loadFeatures(data.features);
        }
      });
  }

  async loadFeatures(
    data: any,
    transform: boolean = false,
    replaceData = true
  ): Promise<void> {
    try {
      // Captura de datos
      if (replaceData || this.data.length === 0) {
        this.data = data;
      }

      // Si transform es true convierte coordenadas magna a google
      if (!transform) {
        const featuresCollection = {
          type: 'FeatureCollection',
          crs: {
            type: 'name',
            properties: { name: 'urn:ogc:def:crs:EPSG::900913' },
          },
          features: data,
        };

        this.vectorSource.addFeatures(
          new GeoJSON().readFeatures(featuresCollection)
        );
      } else {
        let features: any = {
          type: 'FeatureCollection',
          features: data,
        };

        // Lee geojson y reproyecta a sistema de coordenadas google mercator
        features = new GeoJSON().readFeatures(features, {
          dataProjection: 'EPSG:10001',
          featureProjection: 'EPSG:900913',
        });

        this.vectorSource.addFeatures(features);
      }
      return Promise.resolve();
    } catch (e: any) {
      console.error(e.message);
      return Promise.reject();
    }
  }

  styleVector(feature: any) {
    let styleElement: any, icon: any, fill: any, stroke: any;

    // Verifica si para mostrar iconos viene una clasificacion
    if (
      feature?.getProperties() !== undefined &&
      this.styleFeature.field !== undefined
    ) {
      const properties = feature.getProperties();
      const item = properties[this.styleFeature.field];

      if (this.styleFeature.range) {
        const matchRange = Object.keys(this.styleFeature.range).find((key) => {
          const range = JSON.parse(key);
          if (range.length === 1) {
            return item >= range[0];
          } else {
            return item >= range[0] && item < range[1];
          }
        });

        if (matchRange) {
          styleElement = this.styleFeature.range[matchRange];
        } else {
          styleElement = {
            fill: '255,255,255',
            stroke: '255,255,255',
          };
        }
      } else {
        styleElement = this.styleFeature.value[item];
      }
    } else {
      styleElement = this.styleFeature;
    }

    if (styleElement.fill !== undefined) {
      fill = styleElement.fill;
      stroke = styleElement.stroke;
    }

    this.fillColor = fill ? fill : this.fillColor;
    this.strokeColor = stroke ? stroke : this.strokeColor;

    if (this.fillColor) {
      icon = styleElement;
    }

    return new Style({
      fill: new Fill({
        color: 'rgba(' + this.fillColor + ', ' + this.fillTransparent + ')',
      }),
      stroke: new Stroke({
        color: 'rgba(' + this.strokeColor + ')',
        width: 2,
      }),
      text: new Text({
        font: '11px bold "Helvetica Neue", Arial, Helvetica, sans-serif',
        text: '',
        fill: new Fill({
          color: 'rgba(255, 255, 255)',
        }),
      }),
      image:
        this.typeGeometry === 'point'
          ? icon
            ? new Icon({ src: icon })
            : new CircleStyle({
                radius: 7,
                fill: new Fill({
                  color:
                    'rgba(' +
                    this.fillColor +
                    ', ' +
                    this.fillTransparent +
                    ')',
                }),
                stroke: new Stroke({
                  color: 'rgba(' + this.strokeColor + ')',
                  width: 2,
                }),
              })
          : null,
    });
  }

  styleVectorWebGL(): any {
    const clasificaction_icon_vector = this.layer.clasificaction_icon_vector,
      colorFeature: any[] = [];

    let fillFeature = [
        'rgba(' + this.fillColor + ', ' + this.fillTransparent + ')',
      ],
      strokeFeature = [
        'rgba(' + this.fillColor + ', ' + this.fillTransparent + ')',
      ],
      icon_vector: string = null;

    if (clasificaction_icon_vector?.value) {
      colorFeature.push('match');
      colorFeature.push(['get', clasificaction_icon_vector.field]);
      for (const key in clasificaction_icon_vector.value) {
        colorFeature.push(key);
        colorFeature.push(
          'rgba(' +
            clasificaction_icon_vector.value[key].fill +
            ', ' +
            this.fillTransparent +
            ')'
        );
      }
      colorFeature.push('white');
    } else if (this.layer.icon_vector) {
      icon_vector = this.layer.icon_vector;
    } else if (this.layer.rgb_fill_color_vector) {
      fillFeature = [this.layer.rgb_fill_color_vector];
      strokeFeature = [this.layer.rgb_stroke_color_vector];
      // para point
      const fillPointColor = this.layer.rgb_fill_color_vector
        .split(',')
        .map(Number);
      colorFeature.push('color');
      colorFeature.push(fillPointColor[0]);
      colorFeature.push(fillPointColor[1]);
      colorFeature.push(fillPointColor[2]);
      colorFeature.push(this.fillTransparent);
    }

    return this.typeGeometry === 'point'
      ? icon_vector
        ? {
            symbol: {
              symbolType: SymbolType.IMAGE,
              src: icon_vector,
              size: [20, 24],
              rotateWithView: false,
              offset: [0, 0],
            },
          }
        : {
            symbol: {
              symbolType: SymbolType.CIRCLE,
              color: colorFeature,
              size: [14, 14],
              rotateWithView: false,
              offset: [0, 0],
            },
          }
      : {
          fill: {
            color: fillFeature,
          },
          stroke: {
            color: strokeFeature,
          },
        };
  }

  styleCluster(features: any) {
    const label = features.get('features').length,
      size = parseFloat(label),
      min = 12,
      max = 40,
      calc = size * 0.007;

    let new_size = min + calc,
      style: any;

    if (size !== 1) {
      if (new_size > max) {
        new_size = max;
      } else if (new_size < min) {
        new_size = min;
      }

      style = new Style({
        image: new Circle({
          radius: new_size,
          stroke: new Stroke({
            color: 'rgba(' + this.fillColor + ')',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(' + this.strokeColor + ', 0.6)',
          }),
        }),
        text: new Text({
          text: size.toString(),
          font: 'bold 11px Arial, Verdana, Helvetica, sans-serif',
          fill: new Fill({
            color: '#fff',
          }),
        }),
      });
    } else {
      style = this.styleVector(features.getProperties().features[0]);
    }

    return style;
  }

  clear(): void {
    this.vectorSource.clear();
  }

  getData() {
    return this.data;
  }

  setData(data: any) {
    this.data = data;
  }

  getFeatures() {
    return new GeoJSON().writeFeaturesObject(this.vectorSource.getFeatures())
      .features;
  }

  getLayer() {
    return this.vector;
  }

  getSource() {
    return this.vectorSource;
  }

  getExtent() {
    return this.vectorSource.getExtent();
  }

  setMinZoom(minZoom: number) {
    this.vector.setMaxResolution(minZoom);
  }

  getParam(format: TypeFormat): string {
    return `?VERSION=1.0.0&REQUEST=GETFEATURE&OUTPUTFORMAT=${format}&SRSNAME=EPSG:4326&SERVICE=WFS&TYPENAME=`;
  }

  download(format: TypeFormat): void {
    const url = this.urlService + this.getParam(format) + this.nameVector;

    fetch(url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        Download.open(blob, `${this.nameVector}.zip`)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
}
