export class Layer {
  id: number;
  name: string;
  title: string;
  resume: string;
  file: string;
  format_image: string;
  type_service: string;
  key_service_satelital: string;
  type_service_satelital: string;
  type_base: string;
  type_vector: string;
  rgb_fill_color_vector: string;
  rgb_stroke_color_vector: string;
  icon_vector: string;
  type_geometry: string;
  group: string;
  autor: string;
  year: string;
  update_on_activate_switcher: string;
  activate_layers: [];
  base_layer: boolean;
  visibility: boolean;
  search_click: string;
  buffer_search_click: number;
  profile: string;
  date_publication: string;
  layer: string;
  clasificaction_icon_vector: any;
  logo_layer: string;
  metadatos: string;
}
