import { Injectable, EventEmitter } from '@angular/core';
import { ControlInterface } from '../models/control-interface';
import { OptionAction } from 'src/app/shared/globals/enums/option-action';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlInterfaceService {
  private eventUpdate$ = new BehaviorSubject<ControlInterface>(this.init());
  private controls: ControlInterface;

  init(): ControlInterface {
    this.controls = {
      content: OptionAction.close,
      results: OptionAction.close,
      panelSearch: OptionAction.open,
      buttonLayers: OptionAction.open,
      buttonHelp: OptionAction.open,
      buttonDowload: OptionAction.open,
    };

    return this.controls;
  }

  getEventUpdate$() {
    return this.eventUpdate$.asObservable();
  }

  setContent(action: OptionAction) {
    this.controls.content = action;
    this.emitUpdate(this.controls);
  }

  setResults(action: OptionAction) {
    this.controls.results = action;
    this.emitUpdate(this.controls);
  }

  setPanelSearch(action: OptionAction) {
    this.controls.panelSearch = action;
    this.emitUpdate(this.controls);
  }

  setButtonLayers(action: OptionAction) {
    this.controls.buttonLayers = action;
    this.emitUpdate(this.controls);
  }

  setButtonHelp(action: OptionAction) {
    this.controls.buttonHelp = action;
    this.emitUpdate(this.controls);
  }

  setButtonDowload(action: OptionAction) {
    this.controls.buttonDowload = action;
    this.emitUpdate(this.controls);
  }

  private emitUpdate(controls) {
    this.eventUpdate$.next(controls);
  }
}
