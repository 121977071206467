export class Export {
  static csv(features: any): string {
    try {
      // Verificar si hay features
    if (!features || features.length === 0) {
      console.warn('No hay features para convertir');
      return '';
  }

  // Obtener todas las propiedades únicas de los features
  const allProperties = new Set();
  features.forEach(feature => {
      Object.keys(feature.getProperties()).forEach(prop => {
          // Excluir la geometría de las propiedades
          if (prop !== 'geometry') {
            allProperties.add(prop);
        }
      });
  });

  // Convertir Set a Array y crear el encabezado del CSV
  const headers = Array.from(allProperties);
  let csv = headers.join(',') + '\n';

  // Agregar cada feature como una fila
  features.forEach(feature => {
      const row = headers.map(header => {
          const value = feature.get(header) || '';
          // Escapar comas y comillas si es necesario
          return typeof value === 'string' && (value.includes(',') || value.includes('"'))
              ? `"${value.replace(/"/g, '""')}"` 
              : value;
      });
      csv += row.join(',') + '\n';
  });

  return csv;
    } catch (e) {
      console.error(e);
    }
  }
}