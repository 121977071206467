import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { RouteReuseStrategy } from '@angular/router';
import { Keyboard } from '@ionic-native/keyboard/ngx';

// Acceso a GPS
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicSelectableModule } from 'ionic-selectable';

import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Device } from '@ionic-native/device/ngx';

import { MessageInterceptorService } from './shared/globals/interceptors/message-interceptor.service';
import { JwtInterceptorService } from './shared/globals/interceptors/jwt-interceptor.service';
import { LocalstorageService } from './shared/globals/services/localstorage.service';
import { ServiceWorkerModule } from '@angular/service-worker';

import { MapService } from './modules/map/services/map.service';
import { SwitcherService } from './modules/map/services/switcher.service';
import { GeolocationService } from './modules/map/services/geolocation.service';
import { SearchService } from './modules/map/services/search.service';
import { LoginService } from './modules/login/services/login.service';

import { environment } from '../environments/environment';

import { SplashComponent } from './shared/components/splash/splash.component';
import { TermsComponent } from './shared/components/terms/terms.component';
import { ContactComponent } from './shared/components/contact/contact.component';
import { StatsService } from './modules/map/services/stats.service';

@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    TermsComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicSelectableModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    Geolocation,
    Keyboard,
    MapService,
    SwitcherService,
    StatsService,
    SearchService,
    GeolocationService,
    AndroidPermissions,
    LocationAccuracy,
    LoginService,
    // tslint:disable-next-line: deprecation
    FileTransfer,
    FileOpener,
    File,
    Device,
    LocalstorageService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MessageInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
