import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class SearchGenericService {
  public urlApi = environment.apiUrl;
  public controlMensaje: any = null;
  public mensaje: string = null;

  constructor(
    private toastCtrl: ToastController,
    private loadingController: LoadingController,
    private http: HttpClient
  ) {}

  get$ = (
    params: any,
    url: string,
    mensaje = '',
    loading = false
  ): Observable<any> => {
    this.mensaje = mensaje;

    if (this.mensaje) {
      if (loading) {
        this.loadingController
          .create({
            message: this.mensaje,
          })
          .then((control) => {
            this.controlMensaje = control;
            this.controlMensaje.present();
          });
      } else {
        this.controlMensaje = this.toastCtrl
          .create({
            message: this.mensaje,
            position: 'bottom',
          })
          .then((control) => {
            this.controlMensaje = control;
            this.controlMensaje.present();
          });
      }
    }

    return this.http.post<any>(this.urlApi + url, params, httpOptions).pipe(
      map((data) => {
        // Cierra mensaje actual
        if (this.mensaje) {
          this.controlMensaje.dismiss();
        }

        return data.datos;
      }),
      catchError((error: any) => {
        // Cierra mensaje actual
        if (this.mensaje) {
          this.controlMensaje.dismiss();
        }

        return of(console.error(error));
      })
    );
  };
}
