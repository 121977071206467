import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { HelpService } from '../../../modules/home/content/help/services/help.service';
import { Help } from '../../../modules/home/content/help/model/help';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  public help = new Help();

  constructor(
    public modalController: ModalController,
    public helpService: HelpService
  ) {
    this.help.id = 24; // Politicas de uso

    this.show(this.help);
  }

  ngOnInit() {}

  async clickRegresarSplash() {
    this.modalController.dismiss();
  }

  show(help: Help) {
    this.helpService.get$(help).subscribe(nHelp => {
      if (nHelp) {
        this.help = nHelp;
      }
    });
  }
}
