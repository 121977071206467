import { Injectable } from '@angular/core';
import { LineString, Polygon } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere';
import Draw from 'ol/interaction/Draw';
import { Overlay } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import GeometryType from 'ol/geom/Geometry';
import { unByKey } from 'ol/Observable';
import OverlayPositioning from 'ol/Overlay';
import VectorLayer from 'ol/layer/Vector';

import { MapService } from '../services/map.service';

@Injectable({
  providedIn: 'root',
})
export class MeasureService {
  // elementos
  public sketch: any;
  private helpTooltipElement: HTMLElement;
  private helpTooltip: Overlay;
  private measureTooltipElement: HTMLElement;
  private measureTooltip: Overlay;
  private draw: any;
  private typeSelect = 'Polygon';
  private source = new VectorSource();
  private active = false;
  private mapService: MapService;

  // mensajes
  public continuePolygonMsg =
    'Click para dibular un poligono, doble clic para terminar';
  public continueLineMsg =
    'Click para dibular una linea, doble clic para terminar';

  constructor() {}

  initDraw(type: string) {
    // Selecciona el tipo de dibujo a realizar: Polygon o LineString
    this.typeSelect = type;

    // Habilita el dibujo en el mapa
    this.setActive(true);
  }

  setMapService(mapService: MapService) {
    this.mapService = mapService;

    const vector = new VectorLayer({
      source: this.source,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      }),
    });

    this.mapService.addLayer(vector);
  }

  pointerMoveHandler(evt: any) {
    if (evt.dragging) {
      return;
    }

    let helpMsg = 'Click para iniciar el dibujo';

    if (this.sketch) {
      const geom = this.sketch.getGeometry();
      if (geom instanceof Polygon) {
        helpMsg = this.continuePolygonMsg;
      } else if (geom instanceof LineString) {
        helpMsg = this.continueLineMsg;
      }
    }

    this.helpTooltipElement.innerHTML = helpMsg;
    this.helpTooltip.setPosition(evt.coordinate);

    this.helpTooltipElement.classList.remove('hidden');
  }

  formatLength(line: any) {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
    } else {
      output = Math.round(length * 100) / 100 + ' ' + 'm';
    }
    return output;
  }

  formatArea(polygon: any) {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
      output =
        Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
    } else {
      output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
    }
    return output;
  }

  addInteraction() {
    const typeGeom = this.typeSelect === 'Polygon' ? 'Polygon' : 'LineString';
    let listener;

    this.draw = new Draw({
      source: this.source,
      type: typeGeom,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
        }),
      }),
    });
    this.mapService.addInteraction(this.draw);

    this.createMeasureTooltip();
    this.createHelpTooltip();

    this.draw.on('drawstart', (evt: any) => {
      // set sketch
      this.sketch = evt.feature;

      let tooltipCoord = evt.coordinate;

      listener = this.sketch.getGeometry().on('change', (event: any) => {
        const geom = event.target;
        let output: any;
        if (geom instanceof Polygon) {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        this.measureTooltipElement.innerHTML = output;
        this.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.draw.on('drawend', () => {
      this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
      this.measureTooltip.setOffset([0, -7]);
      // unset sketch
      this.sketch = null;
      // unset tooltip so that a new one can be created
      this.measureTooltipElement = null;
      this.createMeasureTooltip();
      unByKey(listener);
    });
  }

  createHelpTooltip() {
    if (this.helpTooltipElement) {
      this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
    }
    this.helpTooltipElement = document.createElement('div');
    this.helpTooltipElement.className = 'ol-tooltip hidden';
    this.helpTooltip = new Overlay({
      element: this.helpTooltipElement,
      offset: [15, 0],
      positioning: 'center-left',
    });
    this.mapService.addOverlay(this.helpTooltip);
  }

  createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.parentNode.removeChild(
        this.measureTooltipElement
      );
    }
    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
    });
    this.mapService.addOverlay(this.measureTooltip);
  }

  setActive(active: boolean) {
    if (active) {
      // Remueve interaccion si existe
      if (typeof this.draw !== 'undefined') {
        // Entra borrar interaccion
        this.mapService.removeInteraction(this.draw);
      }

      this.addInteraction();

      this.active = true;
    } else {
      this.mapService.removeInteraction(this.draw);

      this.active = false;
    }
  }

  getActive() {
    if (typeof this.draw === 'undefined') {
      return false;
    } else {
      return this.active;
    }
  }

  clear() {
    this.source.clear();
  }
}
