import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { RecaptchaService } from '../../globals/services/recaptcha.service';
import { SearchGenericService } from '../../globals/services/search-generic.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public formGroupContacto: UntypedFormGroup;
  public url = 'Contact/Send';

  public emailRegExp =
    // tslint:disable-next-line: max-line-length
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

  constructor(
    private formBuilderContacto: UntypedFormBuilder,
    private searchGenericService: SearchGenericService,
    private alertController: AlertController,
    private modalController: ModalController,
    private recaptchaService: RecaptchaService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.captcha();
  }

  buildForm() {
    this.formGroupContacto = this.formBuilderContacto.group({
      captcha_token: ['', Validators.required],
      nombre_contacto: ['', Validators.required],
      correo_contacto: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(this.emailRegExp),
        ],
      ],
      asunto: [[], Validators.required],
      mensaje: ['', Validators.required],
      error: [''],
    });
  }

  async captcha() {
    try {
      const token = await this.recaptchaService.getToken();
      this.formGroupContacto.patchValue({
        captcha_token: token,
      });
    } catch (e) {
      console.error(e);
    }
  }

  send() {
    this.searchGenericService
      .get$(this.formGroupContacto.value, this.url, 'Enviando mensaje', true)
      .subscribe(async (data: any) => {
        this.enviado('Mensaje enviado, gracias por contáctarnos!');
      });
  }

  close() {
    this.modalController.dismiss();
  }

  async enviado(mensaje: string) {
    const alert = await this.alertController.create({
      header: 'Contacto',
      message: mensaje,
      buttons: [
        {
          text: 'Aceptar',
          handler: () => {
            this.close();
          },
        },
      ],
    });

    await alert.present();
  }
}
