export class Download {
    static open(content: any, nameFile: string): void {
      try {
        const a = document.createElement('a');
        const url = URL.createObjectURL(content);
        a.href = url;
        a.download = nameFile;
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        console.error(e);
      }
    }
}