import { Injectable } from '@angular/core';

import { load } from 'recaptcha-v3';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  keyCaptcha = '6LfuKOMUAAAAAKS8YVvTVIvrALWSqhvnjAvYZyLa';
  recaptcha: any;
  token: string;

  constructor() {}

  async getToken() {
    this.recaptcha = await load(this.keyCaptcha);
    this.token = await this.recaptcha.execute('contact_captcha_validation');
    this.recaptcha.hideBadge();
    return this.token;
  }
}
