import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MapService } from '../services/map.service';
import { ToastController } from '@ionic/angular';

import { ActiveLayers } from '../models/active-layers';
import { StatsLayers } from '../models/stats-layers';
import { Stat } from '../models/stat';
import { Router } from '@angular/router';
import { ControlInterfaceService } from '../../home/services/control-interface.service';
import { OptionAction } from 'src/app/shared/globals/enums/option-action';
import { StatsService } from './stats.service';

@Injectable({
  providedIn: 'root',
})
export class SwitcherService {
  private activeLayers: ActiveLayers[] = [];
  private statsLayers: StatsLayers[] = [];

  // Eventos del switche
  private clickLayer$ = new BehaviorSubject<any>({});

  constructor(
    private mapService: MapService,
    private statsService: StatsService,
    private toastCtrl: ToastController,
    private controlInterfaceService: ControlInterfaceService,
    private router: Router
  ) {
    this.mapService.getMapLoaded$().subscribe((loaded) => {
      if (loaded) {
        this.loadActiveLayers();
      }
    });
  }

  private loadActiveLayers() {
    // Obtiene configuracion de layers del mapa
    const layersConfig = this.mapService.getLayersConfig();

    for (const index in layersConfig) {
      // Define paramestros capa activa
      const activeLayers: ActiveLayers = new ActiveLayers();

      // Captura datos del layer
      activeLayers.name = layersConfig[index].name;
      activeLayers.label = layersConfig[index].resume;
      activeLayers.baseLayer = false;
      activeLayers.visible = false;
      activeLayers.group = layersConfig[index].group;
      activeLayers.updateOnActivateSwitcher = layersConfig[index].updateOnActivateSwitcher;
      activeLayers.activateLayers = layersConfig[index].activate_layers;
      activeLayers.author = layersConfig[index].autor;
      activeLayers.year = layersConfig[index].year;
      activeLayers.layer = layersConfig[index].layer;
      activeLayers.title = layersConfig[index].title;
      activeLayers.typeService = layersConfig[index].type_service;
      activeLayers.typeVector = layersConfig[index].type_vector;
      activeLayers.rgbFillColorVector = layersConfig[index].rgb_fill_color_vector;
      activeLayers.rgbStrokeColorVector = layersConfig[index].rgb_stroke_color_vector;
      activeLayers.iconVector = layersConfig[index].icon_vector;
      activeLayers.typeGeometry = layersConfig[index].type_geometry;
      activeLayers.stats = layersConfig[index].stats;
      activeLayers.metadatos = layersConfig[index].metadatos;

      // Agrega a las capas activas
      this.activeLayers[activeLayers.name] = activeLayers;
    }

    return this.activeLayers;
  }

  getClickLayer$() {
    return this.clickLayer$.asObservable();
  }

  getActiveLayers() {
    return this.activeLayers;
  }

  getVisibleLayers(): any[] {
    // Retorna las capas visibles en el mapa
    const visibleLayers = [];

    for (const key in this.activeLayers) {
      if (this.activeLayers[key].visible) {
        visibleLayers.push(this.activeLayers[key]);
      }
    }

    return visibleLayers;
  }

  getStatsLayers(): any {
    // Retorna las capas visibles en el mapa, inicializa
    let statsLayers = undefined;

    for (const key in this.activeLayers) {
      if (this.activeLayers[key].visible && this.activeLayers[key].stats) {
        if (!statsLayers) {
          statsLayers = [];
        }

        statsLayers[key] = this.statsLayers[key];
      }
    }

    if (statsLayers) {
      this.statsService.setActiveDragBox(true);
    } else {
      this.statsService.setActiveDragBox(false);
    }

    return statsLayers;
  }

  refreshGroupLayers(nameGroup: string) {
    for (const key in this.activeLayers) {
      if (this.activeLayers[key].group === nameGroup) {
        if (this.activeLayers[key].visible) {
          const layer = this.mapService.getLayer(key);
          const source = layer.getSource();

          source.updateParams({ time: Date.now() });
        }
      }
    }
  }

  updateFilterStats(pos: any) {
    // Recoge la posicion del stat y mantiene actualizado en el array de stats los filtros aplicados
    if (this.statsLayers[pos.layer]) {
      this.statsLayers[pos.layer].stats[pos.group].stats[pos.stat].filter =
        pos.filter;
    }
  }

  async resetFiltersStats(nameLayer: string): Promise<void> {
    try {
      // Recoge la posicion del stat y mantiene actualizado en el array de stats los filtros aplicados
      if (this.statsLayers[nameLayer]) {
        // this.statsLayers[nameLayer].filterDragBox = null;
        this.statsLayers[nameLayer] = null;
      }

      await Promise.resolve();
    } catch (e: any) {
      console.error(e.message);
      await Promise.reject();
    }
  }

  async updateFilterDragBoxStats(nameLayer: string, data: any): Promise<void> {
    try {
      // Recoge la posicion del stat y mantiene actualizado en el array de stats los filtros aplicados
      if (this.statsLayers[nameLayer]) {
        this.statsLayers[nameLayer].filterDragBox = data;
      }

      await Promise.resolve();
    } catch (e: any) {
      console.error(e.message);
      await Promise.reject();
    }
  }

  async setVisibleLayer(
    nameLayer: string,
    visible: boolean,
    logoSwitch = null,
    titleStats = null
  ) {
    if (!this.activeLayers[nameLayer]) {
      return;
    }

    // Si lo estados son iguales sale
    if (this.activeLayers[nameLayer].visible === !visible) {
      return;
    }

    this.activeLayers[nameLayer].visible = !visible;
    // Recorre las capas que se deden mostrar al activar la capa actual
    for (const index in this.activeLayers[nameLayer].activateLayers) {
      const activateLayer = this.activeLayers[nameLayer].activateLayers[index];

      if (activateLayer) {
        this.activeLayers[activateLayer].visible = !visible;
        await this._setVisibleLayer(activateLayer, visible);
      }
    }

    await this._setVisibleLayer(nameLayer, logoSwitch, titleStats);

    // Dispara evento click layer
    const layers = {
      visibles: this.getVisibleLayers(),
      stats: this.getStatsLayers(),
    };

    // Dispara evento click layer
    this.clickLayer$.next(layers);
  }

  private async _setVisibleLayer(
    nameLayer: string,
    logoStats = null,
    titleStats = null
  ) {
    let stats: Stat = null;
    let layer = this.mapService.getLayer(nameLayer);

    // Verifica el tipo de layer
    if (this.activeLayers[nameLayer].typeService === 'vector') {
      // Captura vector
      const vectorLayer = layer;

      // Captura el layer del vector
      layer = vectorLayer.getLayer();

      // Procedimiento para estadisticas
      if (this.activeLayers[nameLayer].visible) {
        // Abre mensaje mientras carga la capa
        const toast = await this.toastCtrl.create({
          message: 'Cargando información... espere un momento por favor',
          position: 'middle',
          cssClass: 'qmap-toast-process',
        });
        toast.present();

        // Carga datos del vector desde url
        await vectorLayer.load();

        // Si objecto no existe lo crea
        if (
          !this.statsLayers.length ||
          typeof this.statsLayers[nameLayer] === 'undefined'
        ) {
          // Objecto estadisticas
          stats = {
            stats: this.activeLayers[nameLayer].stats,
            vector: vectorLayer,
            logo: logoStats,
            title: titleStats,
          };

          // Cargar estadisticas en vector de estadisticas
          this.statsLayers[nameLayer] = stats;

          // mostrar estadisticas
          if (stats.stats) {
            this.router.navigate(['/Content/Stats']);
            this.controlInterfaceService.setContent(OptionAction.open);
          }
        }

        // Cierra mensaje despues de cargar
        toast.dismiss();
      } else {
        // Limpia la capa
        vectorLayer.clear();
        // Quita las estadisticas
        this.statsLayers[nameLayer] = {};
      }
    }

    // Si es visible
    if (this.activeLayers[nameLayer].visible) {
      // control capade seleccion
      const layerSelect = this.mapService.getVectorSelect().getLayer();
      this.mapService.removeLayer(layerSelect);

      // Adiciona el layer al servicio del mapa
      this.mapService.addLayer(layer);

      // Adiciona el layer select nuevamente para asegurarse que siempre sea la ultima
      this.mapService.addLayer(layerSelect);

      // Hace la capa visible
      layer.setVisible(true);
    } else {
      // Si no es visible
      // Remueve el layer del mapa
      layer.setVisible(false);
      this.mapService.removeLayer(layer);
    }
  }
}
