export class Download {
  static open(content: any, fileName: string): void {
    try {
      const a = document.createElement('a');
      const url = URL.createObjectURL(content);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    }
  }

  static openCreateBlob(content: any, fileName: string, mimeType: string): void {
    try {
      const a = document.createElement('a');
      const blob = new Blob([content], { type: mimeType });
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
    }
  }
}