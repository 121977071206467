import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';

import { Help } from '../model/help';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  public url = environment.apiUrl + 'Help/';
  public help: Observable<Help[]>;

  constructor(private http: HttpClient) {}

  get$ = (help: Help): Observable<any> =>
    this.http.post<any>(this.url + 'Get', help, httpOptions).pipe(
      map((data) => data.datos),
      catchError((error) => of(console.error(error)))
    );

  getAll$ = (): Observable<any> => {
    // Cache it once if configs value is false
    if (!this.help) {
      this.help = this.http.get<any>(this.url + 'GetAll').pipe(
        map((data) => data.datos),
        publishReplay(1), // this tells Rx to cache the latest emitted
        refCount(), // and this tells Rx to keep the Observable alive as long as there are any Subscribers
        catchError((error) => of(console.error(error)))
      );
    }

    return this.help;
  };

  clear() {
    this.help = null;
  }
}
