export class Help {
    id: number;
    referencia: string;
    titulo: string;
    texto: string;
    icono: string;
    activo: string;
    palabra_clave: string;
    cod_clasificacion: number;
    clasificacion: string;
    criterio: string;
}
