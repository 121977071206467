import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private url = environment.apiUrl + 'Map/';

  constructor(private http: HttpClient) {}

  public config$ = (): Observable<any> =>
    this.http.get<any>(this.url + 'Get').pipe(
      map((data) => data.datos),
      catchError((error) => of(console.error(error)))
    );

  // tslint:disable-next-line: max-line-length
  public wmtsCapabilities$ = (urlWmtsCapabilities: string): Observable<any> =>
    this.http.get(urlWmtsCapabilities, { responseType: 'text' }).pipe(
      tap((data) => data),
      catchError((error) => of(console.error(error)))
    );
}
