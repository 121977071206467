import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  public url = environment.apiUrl + 'Search/';
  private results$ = new BehaviorSubject<any[]>([]);
  private title: string;
  private item: any;
  private search: any;
  private stats: any;
  private photos: string[];
  private urlPhotos: string;

  constructor(private http: HttpClient) {}

  criterio$ = (criterio: any): Observable<any> =>
    this.http.post<any>(this.url + 'Criterio', criterio, httpOptions).pipe(
      map((data) => {
        const results = data.datos;

        // Dispara obervable con informando resultados
        this.results$.next(results);

        // retorno
        return results;
      }),
      catchError((error) => of(console.error(error)))
    );

  getResults$() {
    return this.results$.asObservable();
  }

  setTitle(title: any) {
    this.title = title;
  }

  getTitle() {
    return this.title;
  }

  selItem(item: any) {
    this.item = item;
  }

  getItem() {
    return this.item;
  }

  setSearch(search: any) {
    this.search = search;
  }

  getSearch() {
    return this.search;
  }

  clearResults() {
    this.results$.next(null);
  }

  setStats(stats: any) {
    this.stats = stats;
  }

  getStats() {
    return this.stats;
  }

  setPhotos(photos: string) {
    this.photos = photos.split(',');
  }

  getPhotos() {
    return this.photos;
  }

  setUrlPhotos(url: string) {
    this.urlPhotos = url;
  }

  getUrlPhotos() {
    return this.urlPhotos;
  }
}
