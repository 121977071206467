
<div class="qmap-header qmap-sticky primary">        
  <div class="qmap-label qmap-vertical-align">
    <span class="icon-atras qmap-icon-custom qmap-margin-icon-title qmap-link" (click)="clickRegresarSplash()"></span>
    <h2 class="qmap-label-header">TERMINOS DE USO</h2>
  </div>
</div>
<div class="qmap-marco">
  <div class="qmap-segment">
    <div class="qmap-content-text">
      <span *ngIf="help.texto" [innerHTML]=help.texto></span>
      <span *ngIf="!help.texto">Cargando información...</span>
    </div>
  </div>
  <!-- Agrega espacio al final para espacio de botones que aparecen-->
  <div class="qmap-separator"></div>
  <div class="qmap-separator"></div>
  <div class="qmap-separator"></div>
  <div class="qmap-separator"></div>
</div>
