export class ActiveLayers {
    name: string;
    label: string;
    visible: boolean;
    baseLayer: boolean;
    group: string;
    updateOnActivateSwitcher: string;
    activateLayers: string[];
    author: string;
    year: number;
    layer: string;
    title: string;
    resume: string;
    typeService: string;
    typeVector: string;
    rgbFillColorVector: string;
    rgbStrokeColorVector: string;
    iconVector: string;
    typeGeometry: string;
    stats: {};
    metadatos: string;
}
