import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ConfigService } from './config.service';
import { VectorService } from './vector.service';
import { MeasureService } from './measure.service';

import { Config } from '../models/config';
import { MapConfig } from '../models/map';
import { Layer } from '../models/layer';

import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import { getTopLeft, containsExtent, Extent } from 'ol/extent';
import OSM from 'ol/source/OSM';
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import TileWMS from 'ol/source/TileWMS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import BingMaps from 'ol/source/BingMaps';
import Overlay from 'ol/Overlay';
import proj4 from 'proj4';
import { transform, transformExtent } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import { get as getProjection } from 'ol/proj';
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';
import { toPng } from 'html-to-image';
import { defaults } from 'ol/interaction';
import { SwitcherService } from './switcher.service';
import { Coordinate } from 'ol/coordinate';
import Feature from 'ol/Feature';
import { Pixel } from 'ol/pixel';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  // Control mapa
  private lockMap = false;

  // Propiedades del mapa
  private map: Map;
  private magnaSirgas: any;
  private mercator: any;
  private view: View;
  private wmtsTileGrid: WMTSTileGrid;
  private maxExtent: any = [];
  private centerView: any;
  private mapResolutions: any;
  private config: any;
  private zoomInit: number;

  // Contenedor switcher
  private switcher: any = [];

  // Parametros del switcher base
  private CALLEJERO = '0';
  private SATELITAL = '1';

  // Contenedores de capas
  private baseLayers = {};
  private baseLayersSwitch = {};
  private layers = {};
  private layersConfig = {};
  private vectors = [];
  private layerSelect: any;

  // Eventos del mapa
  private mapLoaded$ = new BehaviorSubject<boolean>(false);
  private clickCoordinates$ = new Subject<any>();
  private selectFeature$ = new BehaviorSubject<any>({});
  private eventMoveEnd$ = new BehaviorSubject<any>({});
  private locateFeature$ = new BehaviorSubject<boolean>(false);

  // Hover Tooltip Feature
  private hoverTooltipElement: HTMLElement;
  private hoverTooltip: Overlay;
  private currentFeature: Feature;

  public exportOptions = {
    filter(element: any) {
      return element.className
        ? element.className.indexOf('ol-control') === -1
        : true;
    },
  };

  constructor(
    private configService: ConfigService,
    private measureService: MeasureService
  ) {}

  loadMap() {
    this.configService.config$().subscribe((mapConfig) => {
      if (mapConfig) {
        this.configService
          .wmtsCapabilities$(
            mapConfig.config.serviceMapproxy +
              '/wmts?service=wmts&request=GetCapabilities'
          )
          .subscribe((capabilities) => {
            this.loadConfig(mapConfig, capabilities);

            this.mapLoaded$.next(true);
          });
      }
    });
  }

  setLockMap(lock: boolean): void {
    this.lockMap = lock;
    if (this.map) {
      this.map.getInteractions().forEach((x) => x.setActive(lock));
    }
  }

  loadConfig(mapConfig: MapConfig, capabilities: WMTSCapabilities) {
    // Toda la config del mapa
    this.config = mapConfig.config;

    // tipos de layers
    const baseCallejero = mapConfig.baseCallejero;
    const baseSatelital = mapConfig.baseSatelital;
    const layers = mapConfig.layers;

    proj4.defs(this.config.geographic, this.config.projGeographic);
    register(proj4);

    this.switcher = this.config.switcher;

    this.baseLayers[this.SATELITAL] = {};
    this.baseLayers[this.CALLEJERO] = {};
    this.baseLayersSwitch[this.SATELITAL] = {};
    this.baseLayersSwitch[this.CALLEJERO] = {};
    this.layersConfig = layers;

    this.magnaSirgas = getProjection(this.config.geographic);
    this.mercator = getProjection(this.config.mercator);

    this.maxExtent = this.config.extentMercator;
    this.centerView = transform(
      this.config.lonlat,
      this.magnaSirgas,
      this.mercator
    );

    this.mapResolutions = this.config.resolutions;
    const maxResolutions = this.config.maxResolutions;
    const zoomCenter = this.config.zoomCenter;
    this.zoomInit = 13;

    this.mercator.setExtent(this.mercator.getExtent());

    this.view = new View({
      extent: this.maxExtent,
      center: this.centerView,
      // resolutions: this.mapResolutions,
      // maxResolution: maxResolutions,
      zoom: this.zoomInit, // zoomCenter
      minZoom: 10,
      maxZoom: 21,
    });

    this.wmtsTileGrid = new WMTSTileGrid({
      origin: getTopLeft(this.maxExtent),
      extent: this.maxExtent,
      resolutions: this.mapResolutions,
      matrixIds: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      tileSize: [256, 256],
    });

    this.map = new Map({
      target: 'map',
      controls: [],
      interactions: defaults({
        doubleClickZoom: !this.lockMap,
        dragPan: !this.lockMap,
        shiftDragZoom: !this.lockMap,
        altShiftDragRotate: !this.lockMap,
        keyboard: !this.lockMap,
        mouseWheelZoom: !this.lockMap,
        pinchZoom: !this.lockMap,
        pinchRotate: !this.lockMap,
      }),
      view: this.view,
    });

    this.loadBaseLayer(baseCallejero, baseSatelital, this.config, capabilities);
    this.loadBaseLayerSwitch(
      baseCallejero,
      baseSatelital,
      this.config,
      capabilities
    );
    this.loadLayers(layers, this.config, capabilities);
    this.createLayerSelect();

    this.switcherBase(this.SATELITAL);

    // TEST SERVICE WMS
    // const wmsMapserver = new TileLayer({
    //   source: new TileWMS({
    //     url: 'https://qmap.quspide.co:8085/wms_ordenamiento_territorial?',
    //     params: {
    //       layers: 'cambiouso'
    //     },
    //     crossOrigin: 'anonymous',
    //     projection: this.mercator,
    //     serverType: 'mapserver'
    //   })
    // });
    // this.map.addLayer(wmsMapserver);

    // eventos
    this.eventClick();
    this.eventMoveEnd();
    this.eventPointerMove();
  }

  getLocateFeature$() {
    return this.locateFeature$.asObservable();
  }

  getMapLoaded$() {
    return this.mapLoaded$.asObservable();
  }

  getClickCoordinates$() {
    return this.clickCoordinates$.asObservable();
  }

  getSelectFeatures$() {
    return this.selectFeature$.asObservable();
  }

  getEventMoveEnd$() {
    return this.eventMoveEnd$.asObservable();
  }

  getResolutions() {
    return this.mapResolutions;
  }

  getMagna() {
    return this.magnaSirgas;
  }

  getMercator() {
    return this.mercator;
  }

  getConfigMap() {
    return this.config;
  }

  getSwitcher() {
    return this.switcher;
  }

  getView() {
    return this.map.getView();
  }

  zoomIn() {
    this.viewAnimate(this.map.getView().getCenter(), this.map.getView().getZoom() + 1, 500);
  }

  zoomOut() {
    this.viewAnimate(this.map.getView().getCenter(), this.map.getView().getZoom() - 1, 500);
  }

  addOverlay(element: Overlay) {
    this.map.addOverlay(element);
  }

  addInteraction(control: any) {
    this.map.addInteraction(control);
  }

  removeInteraction(control: any) {
    this.map.removeInteraction(control);
  }

  addLayer(layer: any) {
    this.map.addLayer(layer);
  }

  getLayer(nameLayer: string) {
    return this.layers[nameLayer];
  }

  setLayer(nameLayer: string, vectorLayer: any) {
    this.layers[nameLayer] = vectorLayer;
  }

  getLayersConfig() {
    return this.layersConfig;
  }

  getMap() {
    return this.map;
  }

  removeLayer(layer: any) {
    this.map.removeLayer(layer);
    this.map.renderSync();
  }

  loadBaseLayer(
    layersCallejero: Layer[],
    layerSatelital: Layer[],
    config: Config,
    capabilities: WMTSCapabilities
  ) {
    layerSatelital.forEach((item, index) => {
      this.baseLayers[this.SATELITAL][index] = this.createLayer(
        item,
        config,
        capabilities
      );

      this.addLayer(this.baseLayers[this.SATELITAL][index]);
    });

    layersCallejero.forEach((item, index) => {
      this.baseLayers[this.CALLEJERO][index] = this.createLayer(
        item,
        config,
        capabilities
      );

      this.addLayer(this.baseLayers[this.CALLEJERO][index]);
    });
  }

  loadBaseLayerSwitch(
    layersCallejero: Layer[],
    layersSatelital: Layer[],
    config: Config,
    capabilities: WMTSCapabilities
  ) {
    layersSatelital.forEach((item, index) => {
      this.baseLayersSwitch[this.SATELITAL][index] = this.createLayer(
        item,
        config,
        capabilities
      );

      this.addLayer(this.baseLayersSwitch[this.SATELITAL][index]);
    });

    layersCallejero.forEach((item, index) => {
      this.baseLayersSwitch[this.CALLEJERO][index] = this.createLayer(
        item,
        config,
        capabilities
      );

      this.addLayer(this.baseLayersSwitch[this.CALLEJERO][index]);
    });
  }

  loadLayers(layers: Layer[], config: Config, capabilities: WMTSCapabilities) {
    layers.forEach((item: Layer) => {
      // Crea layer
      this.layers[item.name] = this.createLayer(item, config, capabilities);
    });
  }

  createLayerSelect() {
    // Define el tipo de layer para seleccionar en el mapa
    const layer = new Layer();
    layer.name = 'Vector select qmap';
    layer.type_vector = 'vector';
    layer.rgb_stroke_color_vector = '0, 245, 255';

    // Crea el vector
    this.layerSelect = new VectorService();
    this.layerSelect.create(layer, '');

    // Captura el layer del vector
    const vectorlayer = this.layerSelect.getLayer();

    // Adiciona layer al mapa
    this.addLayer(vectorlayer);
  }

  getVectorSelect() {
    return this.layerSelect;
  }

  clearVectorSelect() {
    this.layerSelect.clear();
    // Borra medidas
    this.map.getOverlays().clear();
  }

  reprojectCoordinateToMercator(
    coordinate: Array<number>,
    systemCoordinate: number
  ) {
    const system = getProjection('EPSG:' + systemCoordinate.toString());

    return transform(coordinate, system, this.mercator);
  }

  reprojectCoordinateMercatorToSystem(
    coordinate: Array<number>,
    systemCoordinate: number
  ) {
    const system = getProjection('EPSG:' + systemCoordinate.toString());

    return transform(coordinate, this.mercator, system);
  }

  reprojectCoordinateMercatorToMagna(coordinate: Array<number>) {
    return transform(coordinate, this.mercator, this.magnaSirgas);
  }

  reprojecExtentSystemToMercator(extent: Extent, systemCoordinate: number) {
    const system = getProjection('EPSG:' + systemCoordinate.toString());

    return transformExtent(extent, system, this.mercator);
  }

  moveTo(coordinate: Array<number>, zoom: number, reproject: boolean) {
    let location: Array<number>;

    if (reproject) {
      location = transform(coordinate, this.magnaSirgas, this.mercator);
    } else {
      location = coordinate;
    }

    this.map.getView().setCenter(location);

    if (zoom) {
      this.map.getView().setZoom(zoom);
    }
  }

  addIconLocate(coordinate: Array<number>, reproject: boolean) {
    if (coordinate !== null) {
      this.map.getOverlays().clear();

      let posXY;

      const iconLocateElement = document.createElement('div');

      const pinElement = document.createElement('div');
      pinElement.className = 'app-location-pin';

      const pulseElement = document.createElement('div');
      pulseElement.className = 'app-location-pulse';

      iconLocateElement.appendChild(pinElement);
      iconLocateElement.appendChild(pulseElement);

      const iconLocate: Overlay = new Overlay({
        element: iconLocateElement,
        offset: [5, -15],
      });

      if (reproject) {
        posXY = transform(coordinate, this.magnaSirgas, this.mercator);
      } else {
        posXY = coordinate;
      }

      iconLocate.setPosition(posXY);

      this.addOverlay(iconLocate);
    }
  }

  createLayer(layer: Layer, config: Config, capabilities: any) {
    let nLayer: any;
    let nSource: any;

    if (layer.type_service === 'wmts') {
      const wmtsCapabilities = new WMTSCapabilities().read(capabilities);

      const options = optionsFromCapabilities(wmtsCapabilities, {
        layer: layer.name,
        matrixSet: 'google',
        crossOrigin: 'anonymous',
      });

      nSource = new WMTS(/** @type {!module:ol/source/WMTS~Options} */ options);

      nLayer = new TileLayer({
        preload: Infinity,
        source: nSource,
      });
    } else if (layer.type_service === 'wms') {
      nSource = new TileWMS({
        url: config.serviceWms + '/wms_' + layer.layer.toLowerCase() + '/',
        params: {
          layers: layer.name,
          format: 'image/' + layer.format_image,
        },
        crossOrigin: 'anonymous',
        projection: this.mercator,
      });

      nLayer = new TileLayer({
        preload: Infinity,
        source: nSource,
      });
    } else if (
      layer.type_vector === 'vector' ||
      layer.type_vector === 'vectorWebGL' ||
      layer.type_vector === 'cluster' ||
      layer.type_vector === 'heatmap'
    ) {
      nLayer = new VectorService();
      nLayer.create(
        layer,
        config.serviceWms + '/wms_' + layer.layer.toLowerCase()
      );

      // Agrega a array de cluster
      this.vectors.push(nLayer);
    } else if (layer.type_service === 'bingmaps') {
      nSource = new BingMaps({
        key: layer.key_service_satelital,
        imagerySet: layer.type_service_satelital,
      });

      nLayer = new TileLayer({
        preload: Infinity,
        source: nSource,
      });
    } else if (layer.type_service === 'osm') {
      nSource = new OSM();

      nLayer = new TileLayer({
        preload: Infinity,
        source: nSource,
      });
    }

    return nLayer;
  }

  eventClick() {
    /// Busqueda por click
    this.map.on('singleclick', (event) => {
      if (this.lockMap) {
        return;
      }

      let feature = null;

      // Si esta midiendo no realiza la busqueda
      if (this.measureService.getActive()) {
        return;
      }

      let coordinate = event.coordinate;

      try {
        feature = this.map.forEachFeatureAtPixel(
          event.pixel,
          (features: any) => features
        );
      } catch (error) {
        console.error('Error', error);
      }

      if (feature) {
        if (feature.getGeometry().getType() === 'Point') {
          coordinate = feature.getGeometry().getCoordinates();
        }
      }

      this.addIconLocate(coordinate, false);

      // Dispara evnto click coordenadas
      this.clickCoordinates$.next(coordinate);
    });
  }

  eventMoveEnd() {
    this.map.on('moveend', () => {
      const state = {
        zoom: this.view.getZoom(),
        center: this.view.getCenter(),
        rotation: this.view.getRotation(),
        extent: this.view.calculateExtent(this.map.getSize()),
      };

      this.eventMoveEnd$.next(state);
    });
  }

  eventPointerMove() {
    this.map.on('pointermove', (event) => {
      let feature = null;

      if (this.lockMap) {
        return;
      }

      if (event.dragging) {
        if (this.hoverTooltipElement) {
          this.hoverTooltipElement.style.display = 'none';
        }
        this.currentFeature = undefined;
        return;
      }

      try {
        feature = this.map.forEachFeatureAtPixel(
          event.pixel,
          (features: any,) => features
        );

       this.displayFeatureAtTooltipHover(event, feature);
      } catch (error) {
        console.error('Error', error);
      }
    });
  }

  displayFeatureAtTooltipHover(event: any, feature: Feature) {
    if (feature) {
      if (!this.hoverTooltip) {
        this.hoverTooltipElement = document.createElement('div');
        this.hoverTooltipElement.className = 'ol-tooltip';
        this.hoverTooltip = new Overlay({
          element: this.hoverTooltipElement,
          offset: [15, 0],
          positioning: 'center-left',
        });
        this.addOverlay(this.hoverTooltip);
      }
      // actualiza la posicion
      const coordinates = event.coordinate;
      this.hoverTooltip.setPosition(coordinates);
      // actualiza info
      if (this.currentFeature !== feature) {
        
        this.hoverTooltipElement.style.display = 'block';
        this.hoverTooltipElement.innerText = feature.get('puesto');
        this.currentFeature = feature;
      }      
    } else {
      if (this.hoverTooltip) {
        this.hoverTooltipElement.style.display = 'none';
        this.currentFeature = undefined;
      }
    }    
  }

  selectFeature(feature: any) {
    if (feature) {
      this.selectFeature$.next(feature.getProperties());
    }
  }

  viewFit(extent: Extent, reproject: boolean, reduceZoom = 0) {
    if (reproject) {
      extent = transformExtent(extent, this.magnaSirgas, this.mercator);
    }

    // verifica extent
    const checkExtent = containsExtent(this.maxExtent, extent);

    // Verifica si esta dentro del extent de mapa sino envia el extent del mapa
    if (checkExtent) {
      this.view.fit(extent, {
        size: this.map.getSize(),
        duration: 1500,
        maxZoom: this.view.getZoom() - reduceZoom,
      });
    } else {
      this.extentInit();
    }

    setTimeout(() => {
      // Dispara evento de localizacion de feature
      this.locateFeature$.next(true);
    }, 1600);
  }

  extentInit() {
    this.viewAnimate(this.centerView,this.zoomInit);
  }

  viewAnimate(center: Coordinate, zoom: number, duration = 2000) {
    this.view.animate({ center, duration, zoom });
  }

  switcherBase(index) {
    let keyBaseSwitchVisible = '0',
      indexChange = '0',
      layerPrecompose,
      layerPoscompose;

    if (index === this.CALLEJERO) {
      indexChange = this.SATELITAL;
    } else {
      indexChange = this.CALLEJERO;
    }

    for (const key in this.baseLayers[this.SATELITAL]) {
      this.baseLayers[this.SATELITAL][key].setVisible(index !== this.SATELITAL);
    }

    for (const key in this.baseLayers[this.CALLEJERO]) {
      this.baseLayers[this.CALLEJERO][key].setVisible(index !== this.CALLEJERO);
    }

    for (const key in this.baseLayersSwitch[this.CALLEJERO]) {
      if (key === '0') {
        this.baseLayersSwitch[this.CALLEJERO][key].setVisible(
          index === this.CALLEJERO
        );

        if (index === this.CALLEJERO) {
          keyBaseSwitchVisible = key;
        }
      } else {
        this.baseLayersSwitch[this.CALLEJERO][key].setVisible(false);
      }
    }

    for (const key in this.baseLayersSwitch[this.SATELITAL]) {
      if (key === '0') {
        this.baseLayersSwitch[this.SATELITAL][key].setVisible(
          index === this.SATELITAL
        );

        if (index === this.SATELITAL) {
          keyBaseSwitchVisible = key;
        }
      } else {
        this.baseLayersSwitch[this.SATELITAL][key].setVisible(false);
      }
    }

    for (const key in this.baseLayersSwitch[indexChange]) {
      if (this.baseLayersSwitch[indexChange][key].visibleSwitchBase) {
        if (typeof layerPrecompose !== 'undefined') {
          this.baseLayersSwitch[indexChange][key].unByKey(layerPrecompose);
        }
        if (typeof layerPoscompose !== 'undefined') {
          this.baseLayersSwitch[indexChange][key].unByKey(layerPoscompose);
        }
      }
    }

    layerPrecompose = this.baseLayersSwitch[index][keyBaseSwitchVisible].on(
      'prerender',
      (event: any) => {
        const switcherBase = document.getElementById('switcher-base');

        // Si no hay elemento switcherBase sale
        if (!switcherBase) {
          return;
        }

        if (index === this.CALLEJERO) {
          switcherBase.innerHTML = '<span>Callejero</span>';
          switcherBase.onclick = () => this.switcherBase(indexChange);
        } else if (index === this.SATELITAL) {
          switcherBase.innerHTML = '<span>Bing Maps</span>';
          switcherBase.onclick = () => this.switcherBase(indexChange);
        }

        const ctx = event.context,
          pixelRatio = window.devicePixelRatio || 1,
          left = switcherBase.offsetLeft * pixelRatio,
          top = switcherBase.offsetTop * pixelRatio,
          lado = switcherBase.offsetWidth * pixelRatio;

        ctx.save();
        ctx.beginPath();
        ctx.rect(left, top, lado, lado);
        ctx.strokeStyle = 'rgba(255,255,255,1)';
        ctx.lineWidth = '7';
        ctx.stroke();
        ctx.font = '16px Georgia';
        ctx.clip();
      }
    );

    layerPoscompose = this.baseLayersSwitch[index][keyBaseSwitchVisible].on(
      'postrender',
      (event: any) => {
        const ctx = event.context;
        ctx.restore();
      }
    );
  }

  updateSize(width: number, height: number) {
    this.map.setSize([width, height]);
    this.map.updateSize();
  }

  eventExport() {
    this.map.once('rendercomplete', () => {
      toPng(this.map.getTargetElement(), this.exportOptions).then((dataURL) => {
        let link: any = document.getElementById('qmap-image-download');

        if (!link) {
          link = document.createElement('a');
          link.id = 'qmap-image-download';
          link.download = 'map.png';
        }

        link.href = dataURL;
        link.click();
      });
    });
    this.map.renderSync();
  }
}
