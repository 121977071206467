<div class="qmap-content-center qmap-background-app">
  <div class="qmap-form-component">
    <span
      class="icon-cerrar qmap-cerrar-component qmap-link"
      (click)="close()"
    ></span>
    <div class="qmap-center">
      <img
        class="header-logo-alcaldia"
        src="assets/icon/escudo-cartagena.png"
      />
    </div>

    <!-- Titulo -->
    <div class="qmap-label qmap-icon-title qmap-center qmap-vertical-align">
      <span class="qmap-label-header">Contáctenos</span>
    </div>

    <div class="qmap-content">
      <div class="qmap-marco">
        <form [formGroup]="formGroupContacto">
          <ion-list
            lines="none"
            class="ion-no-margin ion-no-padding qmap-center"
          >
            <!-- Nombres y apellidos -->
            <ion-item class="ion-margin item-form">
              <ion-input
                type="text"
                placeholder="Nombres y apellidos"
                formControlName="nombre_contacto"
              >
                <ion-icon
                  slot="start"
                  class="item-icon"
                  name="person"
                ></ion-icon>
              </ion-input>
            </ion-item>

            <!-- correo -->
            <ion-item class="ion-margin item-form">
              <ion-input
                type="text"
                placeholder="Correo"
                formControlName="correo_contacto"
              >
                <ion-icon slot="start" class="item-icon" name="mail"></ion-icon>
              </ion-input>
            </ion-item>

            <ion-item class="ion-margin item-form">
              <ion-icon
                full
                slot="start"
                class="item-icon"
                name="list"
              ></ion-icon>
              <ion-select placeholder="Asunto" formControlName="asunto">
                <ion-select-option value="Problema técnico"
                  >Problema técnico</ion-select-option
                >
                <ion-select-option value="Sugerencias o comentarios"
                  >Sugerencias o comentarios</ion-select-option
                >
                <ion-select-option value="Otros">Otros</ion-select-option>
              </ion-select>
            </ion-item>

            <!-- mensaje -->
            <ion-item class="ion-margin item-form">
              <ion-textarea
                placeholder="Mensaje"
                formControlName="mensaje"
                rows="4"
              ></ion-textarea>
            </ion-item>

            <!-- Enviar -->
            <ion-button
              class="login-button"
              [disabled]="formGroupContacto.invalid"
              (click)="send()"
            >
              Enviar
            </ion-button>

            <!-- by -->
            <div class="qmap-center">
              <img class="quspide-by" src="assets/icon/by.png" />
            </div>
          </ion-list>
        </form>
        <!-- Agrega espacio al final para espacio de botones que aparecen-->
        <div class="qmap-separator"></div>
        <div class="qmap-separator"></div>
        <div class="qmap-separator"></div>
        <div class="qmap-separator"></div>
        <div class="qmap-separator"></div>
      </div>
    </div>
  </div>
</div>
