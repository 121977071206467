<ion-content fullscreen scroll-y="false">
    <ion-slides pager="true" [options]="slideOptsOne">
        <ion-slide class="slade1"></ion-slide>
        <ion-slide class="slade2"></ion-slide>
    </ion-slides>   
</ion-content>
<div class=qmap-splash-accept-conditions>
  <div *ngIf="!loadMap" class="qmap-vertical-align qmap-center">
    <ion-spinner name="dots"></ion-spinner>
    <span class="qmap-label">
      Cargando mapa...
    </span>
  </div>
  <div *ngIf="loadMap" class="qmap-vertical-align qmap-center">
    <ion-button (click)="clickAceptarTerminos()">
      <ion-icon slot="icon-only" name="checkmark-circle"></ion-icon>
      Acepto
    </ion-button>
    <span class="qmap-label">los <u (click)="clickVerTerminos()" class="qmap-link"><i>terminos y condiciones</i></u> para el uso de MIDAS</span>
  </div>
</div>
